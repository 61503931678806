.dashboard.info-box {
		margin-bottom: 5px;
	
	.info-box-icon {
		border-top-left-radius:2px;
		border-top-right-radius:0px;
		border-bottom-left-radius:2px;
		border-bottom-right-radius:0px;
		display: block;
		float: left;
		width: 90px;
		text-align: center;
		font-size: 45px;
		line-height: 90px;
		background: rgba(0,0,0,0.2);
		height: 96px;
	}
	.info-box-content {
		padding: 5px 10px;
		margin-left: 90px;

	}
	.info-box-text {
		position: relative;
    top: -5px !important;
    font-size: 13pt !important;
		    text-transform: uppercase;
				    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	}
	.info-box-number {
		    display: block;
    font-weight: bold;
    font-size: 18px;
	}
	.progress {
		    background: rgba(0,0,0,0.2);
    margin: 5px -10px 5px -10px;
    height: 2px;
		border-radius: 0;
		.progress-bar {
			background: #fff;
		}
	}
	.progress-description {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	}
}

