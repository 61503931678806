.notes_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.expand_notes_icon {
	cursor: pointer;
	margin-right: 1rem;
}

.show_uploads {
	display: flex;

	&_preview_container {
		width: 17rem;
		padding:1rem;
		margin-right: 0.5rem;
		border: 1px dashed;
		border-radius: 0.3rem;
		height: fit-content;

		.selected_file_preview {
			background: white;
			border-radius: 0.5rem;
			position: relative;
			padding: 1rem;
			height: 9rem;

			.title {
				font-size: 1.3rem;
			}
			.file_type_icon {
				position: absolute;
				bottom:1rem

			
			}
			.action-container {
				position: absolute;
				bottom: 1rem;
				right: 1rem;
				display: flex;
				align-items: center;
				.action {
					cursor: pointer;
					margin-left: 1rem;
				}
			}
		}
	}

	&_file_container {
		flex: 1;
		padding: 0rem 1rem;
		margin-left: 0.5rem;
		display: flex;
		flex-direction: column;


		.file_container {
			background: white;
			cursor: pointer;
			border-radius: 0.5rem;
			padding: 1rem;
			margin-bottom: 1rem;
			display: flex;
			align-items: center;
		}
		.selected_file_container {
			border: 1px solid;
		}
	}
}

.upload-file-form {
	// max-width: 40rem !important;
	// margin: auto;
	width: 100%;
	height: 7rem;
	// padding:3rem 3rem !important;
	margin-top: 1rem;
	margin-bottom: 1rem;
	// margin-left: 5rem;
	.upload_container {
		border: dashed 2px #0658c2;
		height:100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		cursor: pointer;
		
	}
	.file-types {
		display: none;
	}
}