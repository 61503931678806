.pv-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tab_header {
  border-bottom-style: solid;
  border-bottom-color: $gray-200;
  border-bottom-width: 1px;
  margin-bottom: 15px;
}

.nav-pills {
  .nav-link {
    border-style: solid;
    border-color: $gray-200;
    border-width: 1px;
    margin-left: 4px;
    margin-right: 4px;

    &:hover {
      color: $primary !important;
    }

    &.active {
      background: $primary;

      &:hover {
        color: $white !important;
      }
    }
  }
}

.ui-theme-settings {
  position: fixed;
  z-index: 10;

  .btn-open-options {
    @include border-radius(50px);
    position: absolute;
    left: -114px;
    bottom: 7px;
    padding: 0;
    height: 45px;
    line-height: 45px;
    width: 45px;
    text-align: center;
    display: block;
    box-shadow: $box-shadow-default;
    margin-top: -27px;
  }
}

.user-avatar {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
}

.form-header-icon {
  margin: 0px 10px;
  color: $primary !important;
  font-size: 15px;
  cursor: pointer;
}

svg:not(:root).svg-inline--fa {
  vertical-align: -1.125em;
}

.primary .rx-editor {
  background-color: $primary;
}

.secondary .rx-editor {
  background-color: $secondary;
}

.success .rx-editor {
  background-color: $success;
}

.info .rx-editor {
  background-color: $info;
}

.warning .rx-editor {
  background-color: $warning;
}

.danger .rx-editor {
  background-color: $danger;
}

.light .rx-editor {
  background-color: $light;
}

.dark .rx-editor {
  background-color: $dark;
}

.table-column-search {
  .form-group {
    margin-bottom: 0;

    .form-control {
      // height: 29px;
      font-size: 15px;
    }
  }
}

.table-top-info {
  padding: 15px 0;
}

// edit page cms

.dropdown-form {
  padding: 10px;
}

.edit-top-nav-button {
  position: absolute;
  top: 1px;
  right: 0;
  z-index: 111;

  i {
    font-size: 17px !important;
  }
}

.edit-top-nav-page {
  position: absolute;
  top: 1px;
  z-index: 111;
  right: 50px;

  i {
    font-size: 17px !important;
  }
}

.widget-content-left .remove-button {
  position: absolute;
  top: -5px;
  right: 0;
  border-radius: 55%;
  width: 13px;
  height: 13px;
  padding: 0;
  min-width: inherit;
  font-size: 11px;
  cursor: pointer;
}

.auto-complete {
  position: absolute;
  top: 68px;
  width: 88%;
  z-index: 11111;

  .list-group-item {
    cursor: pointer;
  }
}

.select-color {
  background: transparent;
  height: 20px;
  width: 20px;
  float: right;
  margin-left: 11px;
  cursor: pointer;
  position: relative;
  outline: none;
  transition: box-shadow 100ms ease 0s;
  border-radius: 50%;
}

.pointer {
  cursor: pointer;
}

.edit-form-page-button {
  top: 15px;
  margin-bottom: 20px;
}

.sidebar-nav-edit-button {
  text-align: center;
  margin: 10px;
}

.font-10rem {
  font-size: 10rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.widget-content-left .dropdown-menu.dropdown-menu-right {
  transform: translate3d(-758px, 46px, 0px) !important;
}

.header-btn-lg .widget-content-left .dropdown-menu.dropdown-menu-xl {
  transform: translate3d(-758px, 46px, 0px) !important;
}

.app-header-right .dropdown-menu.dropdown-menu-xl {
  min-width: 50rem;
}

// table
.table-input {
  border-width: 0;
  background-color: transparent;
  width: 100%;
}
input.table-input:read-only {
  outline: none !important;
}

.logo-src-name {
  color: rgb(25, 118, 210);
  font-size: 1.5rem;
}

.closed-sidebar {
  .logo-src-container {
    display: none;
  }
}
.react-datetimerange-picker__calendar {
  z-index: 20 !important;
}

.logo-container-edit-form {
  display: flex;
  flex-direction: column;
}
.basic-title {
  color: #6c757d;
  margin: 0 0 3px;
  padding-left: 1em;
  font-weight: 600;
  font-size: 15px;
}
.field-section {
  background: aliceblue;
  border-bottom-width: 0px;
}

.theme_auth_button {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
}

.sidebar-text-dark {
  color: rgba(0, 0, 0, 0.6);
}
tr.sidebar-text-light {
  border: 0.5px solid;
}
.sidebar-text-light {
  color: rgba(255, 255, 255, 0.9);

  input {
    color: rgba(255, 255, 255, 0.9);
  }
  .select__single-value {
    color: rgba(255, 255, 255, 0.9);
  }
}

.react-tabs__tab--selected {
  color: #545cd8 !important;
  border: none !important;
  border-bottom: 2px solid #545cd8 !important;
}
.react-tabs__tab--selected:focus {
  border: none !important;
  border-bottom: 2px solid #545cd8 !important;
}
.react-tabs__tab {
  position: unset !important;
}

.header-filter-date {
  color: inherit;
  width: 100%;
  border: 1px solid #ced4da;
  font-size: 13px;
  padding: 0.375rem 0.75rem;
}

.tableForm {
  flex: 1;
  .table-date {
    background: transparent;
    border: 0px;
    color: inherit;
    width: 100%;
  }
  .table-date-read-only:focus {
    border: none;
  }
  .table-date-read-only:read-only {
    outline: none;
  }

  .controlSelect {
    background-color: transparent;
    border: none;
    min-height: fit-content;
  }

  .form-control {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .select {
    &__control {
      background-color: transparent;
      border: none;
      min-height: fit-content;
    }
    &__option {
      color: rgba(0, 0, 0, 0.6);
    }
    &__option--is-focused {
      // color: rgba(255, 255, 255, 0.9);
    }
    &__indicator {
      display: none;
    }
    &__indicators {
      display: none;
    }
    &__indicator-seperator {
      display: none;
    }
    &__single-value--is-disabled {
      color: black !important;
    }
    &__placeholder {
      color: hsl(0, 0%, 70%) !important;
    }
  }
}

.tableFilter {
  flex: 1;
  .table-date {
    background: transparent;
    border: 0px;
    color: inherit;
    width: 100%;
  }
  .table-date-read-only:focus {
    border: none;
  }
  .table-date-read-only:read-only {
    outline: none;
  }

  .controlSelect {
    background-color: transparent;
    border: none;
    min-height: fit-content;
  }

  .form-control {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .select {
    &__control {
      background-color: #fff;
      border: none;
      min-height: fit-content;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #ced4da;
    }
    &__option {
      color: rgba(0, 0, 0, 0.6);
    }
    &__option--is-focused {
      // color: rgba(255, 255, 255, 0.9);
    }
    &__indicator {
      display: none;
    }
    &__indicators {
      display: none;
    }
    &__indicator-seperator {
      display: none;
    }
    &__single-value--is-disabled {
      color: black !important;
    }
    &__placeholder {
      color: hsl(0, 0%, 70%) !important;
    }
  }
}
.switch-icon {
  font-size: 14px;
  text-align: center;
  padding: 7px 11px;
  color: #fff;
  font-weight: 500;
}

.gridSettigTable {
  i {
    cursor: pointer;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}

.gridFilterTable {
  i {
    cursor: pointer;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}

.select--is-disabled {
  color: black;
}
@media print {
  @page {
    size: A4;
    margin: 0;
  }

  html,
  body {
    width: 210mm; // A4 Paper width
    height: 90%;
  }
  .app-main__outer {
    padding-left: 0 !important;
  }

  .form-group {
    display: none;
  }

  .RRT__tabs {
    display: none;
  }
  .app-header {
    display: none;
  }
  .no-print {
    display: none !important;
  }
  .rc-tabs-bar {
    display: none !important;
  }

  .qr-print-container {
    display: flex;
    flex-direction: column;
  }

  .qr-print-container .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .qr-print-container .col-md-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .qr-print-container .col-md-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .qr-print-container .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .qr-print-container .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .qr-print-container img.qr-image {
    max-width: 100%;
    height: auto;
  }
}

.text-align-center {
  text-align: center !important;
}

.badge-pill-container-grid {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .badge {
    // max-height: 1.5rem;
    width: 6rem;
    cursor: pointer;
    padding: 7px 10px;
    margin-right: 1rem;
    // border-radius: 5px;
  }
  .active-badge {
    border: 2px solid black;
  }
}

.dropdown-menu.show {
  pointer-events: all !important;
}
.RRT__showmore-list {
  z-index: 5000 !important;
}

.RRT__tab {
  // white-space: unset !important;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: fit-content !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.where-clause-date-picker {
  width: 100% !important;
  padding: 0.2rem;
  margin-top: 0.5rem;
}
.remove-outline:focus {
  outline: none;
}
.rx-toolbar-container {
  z-index: 0 !important;
}

.question-date-picker {
  // background: transparent;
  // border: 2px;
  // color: inherit;
  width: 20rem;
  padding: 0.3rem;
}

.signature-pad {
  border: 1px solid black;
  height: 200px;
}
.verticle-devider {
  border-left: 2px solid #e5e5e5;
  padding-bottom: 11px;
}

.disabled-pill {
  pointer-events: none;
  cursor: no-drop !important;
  opacity: 0.7;
}

.grid_note_alert {
  display: flex;
  overflow: hidden;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  transition: all 2s;
}

.blue-border {
  border: 1px solid #30b1ff;
  border-radius: 0.3rem;
}

.green-border {
  border: 1px solid green !important;
  border-radius: 0.3rem;
}

.edit-page-tab {
  z-index: 1 !important;
  // position: relative !important;
}

.react-datepicker-popper {
  z-index: 3000000 !important;
}

.text-time-filter-button {
  background-color: transparent;
  overflow: hidden;
  border: 1px solid #ced4da;
  color: rgba(0, 0, 0, 0.6);

  padding: 0.3rem 0.75rem;
  white-space: nowrap;

  text-overflow: ellipsis;
}

.text-time-dropdown-menu {
  width: '386px' !important;

  padding: 1rem;

  .title {
    color: rgba(0, 0, 0, 0.6);
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.text-time-dropdown-menu.dropdown-menu::before {
  display: none; /* Hide the arrow */
}
.text-time-dropdown-menu.dropdown-menu::after {
  display: none; /* Hide the arrow */
}

.expanded_column {
  &_container {
    padding: 1rem;

    table {
      margin-top: 1rem;
      border: 1px solid #e9ecef;

      th {
        color: #545cd8 !important;
        border: 1px solid #e9ecef;
      }
      td {
        border: 1px solid #e9ecef;
      }
    }
  }
}

.bg-green {
  background: #00a65a !important;
  color: rgba(255, 255, 255, 0.9);
}
.bg-yellow {
  background-color: #f39c12 !important;
  color: rgba(255, 255, 255, 0.9);
}
.bg-orange {
  background-color: #ff851b !important;
  color: rgba(255, 255, 255, 0.9);
}

.bg-red {
  background-color: #dd4b39 !important;
  color: rgba(255, 255, 255, 0.9);
}
.react-datepicker-time__input-container {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.react-datepicker-time__caption {
  text-align: centerf;
}
.react-datetimerange-picker__wrapper {
  border: 1px solid rgb(0, 0, 0, 0.2) !important;
  padding: 3px;
  border-radius: 4px;
  color: rgb(0, 0, 0, 0.6);
}

.no-border {
  border: none !important;
}

.layout-setting-row {
  text-align: center;
  background: rgb(246, 246, 246);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding: 1rem;

  .layout-setting-block {
    background: rgb(228, 237, 245);
    border-radius: 5px;
    padding: 1rem;
    border: 1px dashed rgb(188, 192, 194);
    margin: 0px;
    min-height: 15rem;
    max-height: 15rem;
    overflow: auto;
  }
}

.dashboard-chart-title {
  font-weight: 900;
  font-size: 15px;
  margin-bottom: 1rem;
}

.react-datepicker-wrapper {
  flex: 1;
}

.rti--container {
  padding: 0.4rem !important;
  margin-bottom: 1rem !important;
}
.rti--input {
  width: 100% !important;
}

.chat_infinite_scroll {
  .dropdown-item.active {
    background-color: #30b1ff;
    border-radius: 8px;
  }
}

.email-sidebar {
  .dropdown-item.active {
    background-color: #30b1ff22;
    border-radius: 8px;
    color: #212529;
  }
}

.wizard-single-form-with-child-submit {
  position: absolute;
  bottom: -4rem;
  right: 0;
}

.email-pagination {
  .pagination {
    justify-content: center;
  }
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.input-dollar-icon {
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.cross-badge-sizing {
  width: 1rem;
  height: 1rem;
  top: -6px;
  left: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    width: 0.7rem;
    height: 0.7rem;
  }
}

.select__menu {
  z-index: 1000000;
}

.file-upload-field {
  max-width: 100% !important;
  padding: 3rem !important;
}

::placeholder {
  color: hsl(0, 0%, 70%) !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: hsl(0, 0%, 70%) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.radio-field-wrapper {
  .form-control {
    padding: 5px 10px !important;
  }
  .form-check {
    margin-bottom: 5px;
    margin-left: 5px;
    display: inline-block;
  }
}

.line-clamp-1 {
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.wizard-tab {
  margin: 0;
  padding-left: 0rem;
  padding-right: 3rem;
  list-style-type: none;
  width: 100%;
  display: flex;
  table-layout: fixed;
  border-radius: 0.25rem;
  // justify-content: space-aroun;
  margin-bottom: 3rem;
  // border-bottom: 3px solid #dee2e6;
  border-radius: 5px;
  position: relative;

  li {
    padding: 0.5rem 1rem;
    margin-right: 3rem;
    cursor: pointer;
    color: #00000099;
    font-weight: 500;
  }

  li.active {
    color: #30b1ff;
    border-bottom: 3px solid #545cd8;
  }
  li.visited {
    // color: #3ac47d;
    // border-bottom: 3px solid #3ac47d;
  }
  li.unvisited {
    visibility: hidden;
  }
}

.rc-tabs-tab-next.rc-tabs-tab-btn-disabled.rc-tabs-tab-arrow-show {
  // display: none;
  // opacity: 0.5;
}

.rc-tabs-tab-prev.rc-tabs-tab-btn-disabled.rc-tabs-tab-arrow-show {
  // display: none;
  // opacity: 0.5;
}

.rc-tabs .rc-tabs-nav-scroll {
  transition: transform 3s ease-in-out; /* Adjust the duration as needed */
}

.rc-tabs-tabpane.rc-tabs-tabpane-active {
  min-height: 70vh;
}

.no-box-shadow {
  box-shadow: none !important;
}
.app-link-icon {
  max-width: 13rem;
  margin: 2rem 0rem;
}

.pointer-event-none {
  pointer-events: none !important;
}
.opacity-half {
  opacity: 0.5;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.btn-disabled {
  pointer-events: none;
  opacity: 0.5;
}
