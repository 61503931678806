.email-modal {
  .email-container {
    display: flex;

    .select-duration-title {
      margin-right: 3rem;
      margin-top: 0.5rem;
    }

   
  }
	 .date-container {
      display: flex;
      margin-right: 5.8rem;
      margin-bottom: 1rem;
    }
}
