.copy-tab-grid {
	.item-container {
		padding: 1rem;
		.item {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid rgb(0,0,0,0.2);
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;
			
		}
	}
	
}	