.permission {
  table {
    margin-top: 1rem;
    border: 1px solid #e9ecef;

    th {
      color: #545cd8 !important;
      border: 1px solid #e9ecef;
    }
    td {
      border: 1px solid #e9ecef;
    }
    .checkBox {
      width: 1rem;
      text-align: center;
       width: 5rem;
      overflow: hidden;
   
    }
    .expand {
      width: 1.5rem;
      text-align: center;
    }

    .w-5 {
      width: 5rem;
      overflow: hidden;
    }
      .w-6 {
      width: 6rem;
      overflow: hidden;
    }

    .child-tab-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
