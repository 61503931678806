



.metis-menu-dynamic {
  .selected-nav-link {
    // background-color: rgba(0, 0, 0, 0.1);
    font-weight: 800;
  }
  i {
    font-size: 20px;
    width: 20px;
  }
  button {
    background-color: transparent;
    border: none;
  }
  .nav-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    transition: background-color 0.3s ease;
    width: 100%;
    justify-content: space-between;
    &.sub-item {
      padding: 5px;
      margin-left: 0.9rem;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.active {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 800;
    }

    .bi-chevron-up,
    .bi-chevron-down {
      margin-left: auto;
    }
  }

  .submenu {
    padding-left: 20px;
    display: none;
    position: relative;
    ::before {
      background: #00000001;
      border-radius: 15px;
      content: '';
      height: 100%;
      left: 20px;
      opacity: 0.5;
      position: absolute;
      top: 0;
      width: 3px;
    }

    &.show {
      display: block;
    }
  }
}

.sidebar-text-light {
  .metis-menu-dynamic {
    .nav-link {
      color: rgba(255, 255, 255, 0.7);

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.active {
        color: rgba(255, 255, 255);
      }
    }

    .submenu {
      ::before {
        background: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
