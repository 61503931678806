.item-reports-preview {

  .general-details-container {
    margin-bottom: 2rem;
  }

  .general-details-title {
    font-weight: 600;
    font-size: 1.1rem;
    background-color: #00000005;
    border-bottom: 1px solid #00000018;
    padding: 0.3rem 1rem;
    margin-bottom: 1rem;
  }

  .section-container {
    display: flex;
    padding: 0.5rem 1rem;

    flex-direction: row;
  }
  .left-section {
    flex: 1;
    font-size: 0.9rem;
  }
  .right-section {
    flex: 1;
    font-size: 0.9rem;
  }
  .report-container {
    margin-top: 1rem;
    padding: 2rem;
    padding-top: 0;

    .item-group-title {
      font-weight: 600;
      font-size: 1.1rem;
      background-color: #00000018;
      padding: 0.3rem 1rem;
      margin-bottom: 1rem;
    }
    .item-container {
     margin-bottom: 1rem;

      .item-title {
        font-weight: 600;
        font-size: 0.9rem;
        background-color: #00000009;
        padding: 0.3rem 1rem;
        margin-bottom: 0.7rem;
      }

			.question-container {
				display: flex;
				flex-direction: row;
        margin-bottom: 0.4rem;
				.index {
					width: 2rem;
					text-align: center;
				}

				.question {
					flex: 1;
				}
        .answer {
          width: 10rem;
          overflow: auto;
          text-align: end;
        }
				.comment {
					min-width:10rem;
					margin-left: 1rem;
					flex: 1;
				}
			}
    }
  }
}
