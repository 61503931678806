// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
}

.app-logo-page {
  background-image: url('../../assets/utils/images/logo-inverse.png');
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  height: 10vh;
  padding: 0;
  margin: 0;
}

.auth-page-logo {
  max-width: 200px;
  min-width: 150px;
}

.logoTextStart {
  font-size: 25pt;
  font-weight: 600;
  color: #049fdb;
  text-transform: uppercase;
}

.logoTextEnd {
  font-size: 70pt;
  color: #6c757d;
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('../../assets/utils/images/logo.png');
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: 0.6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}
